.App {
  text-align: center;
}

.App-textarea {
  width: 400px;
  height: 250px;
  display: inline-block;
  font-size: 16pt;
  padding: 10px;
}

.App-output {
  width: 500px;
  display: inline-block;
  position: relative;
  font-family: sans-serif;
}
